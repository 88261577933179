import React from "react";
import { getConfig } from "../config";
import auth0 from 'auth0-js';
import Hero from "../components/Hero";
import { isWebViewContext, postMessage } from "./webViewHelper";
import Loading from "../components/Loading";

const { seamlessLoginPageClientId, domain, host } = getConfig();
const webAuth = new auth0.WebAuth({
  domain,
  clientID: seamlessLoginPageClientId,
  redirectUri: `${host}/mobilesso?sso_completed=true`,
  responseType: 'id_token'
});

const searchParams = new URLSearchParams(document.location.search)



export const callbackFromMobileApp = (json) => {

  localStorage.setItem('targetUri', json.redirectUri);
  try {
    const user_id = json.userId;
    const accessToken = json.accessToken;
    webAuth.login({
      email: `${user_id}@user.id`,
      password: accessToken
    });
  } catch (e) {
    console.log(e.stack);
    throw e;

  }
}

const isSsoCompleted = () => searchParams.get("sso_completed") === "true";

// mobile app will call this function
window.callbackFromMobileApp = callbackFromMobileApp;


const MobileSSOComponent = () => {
  const url = localStorage.getItem('targetUri');

  if (isSsoCompleted()) {
    window.location.href = `${url}`;
    return (
      <Loading />
    );
  }

  if (!isWebViewContext()) { return (<><Hero /><div>This page can only be accessed from the mobile application</div></>) }

  postMessage({ "action": "mobilesso" });
  return (
    <Loading />
  );
};

export default MobileSSOComponent; 
