import React, { Fragment } from "react";

import Hero from "../components/Hero";

const Home = () => (
    <Fragment>
        <Hero />
    </Fragment>
);

export default Home;
