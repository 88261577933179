// webViewHelper.js
const isWebViewContext = () => !!window.ReactNativeWebView;

const postMessage = (message) => {
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export {
    isWebViewContext,
    postMessage,
};
